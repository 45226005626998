import React from 'react'
import './index.css'
const Title = (props) => {
  const { title, textColor, textalign } = props

  
  return (
   
      <h2 className="section_title"
        style={{ color: textColor || `var(--first-color)`, 
        textAlign: textalign || "left" }}>
        {title}
      </h2>
 
  )
}

export default Title
