import React from 'react'
import Title from '../Title/Title'
import './index.css'
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'
import thumbsUp from '../../assets/thumbsUp.png'

const Testimonials = () => {
  return (
    <div id="testimonials" className="containerWidth">
      <Title
        title="testimonials"
        textalign="center"

      />
      <div className="tMain">

        <img
          className="tImg"
          src={thumbsUp} />
        <div className="tContent">
          <p className="tP ">
            <i className="quoteIcon">
              <FaQuoteLeft />
            </i>
            They have excellent data processing and analysis capabilities, and data visualization makes the analysis results clear. We strongly recommend it to other companies that need data solutions, and look forward to working with CloudWalk again.
            <i className="quoteIcon">
              <FaQuoteRight />
            </i>
          </p>
          <p className="tName">Tim Jia</p>
          <p className="tCompany">AGODA</p>
        </div>

      </div>
    </div >
  )
}

export default Testimonials

