import React, { useState } from 'react'
import './index.css'
import logo from '../../assets/logo-no-background.png'
import { navList } from '../../data/navList'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

const Header = () => {
  const [navBg, setnavBg] = useState(false)
  const [toggleMenu, settoggleMenu] = useState(false)

  const changeBg = () => {
    if (window.scrollY >= 100) {
      setnavBg(true)
    } else {
      setnavBg(false)
    }
  }

  // LISTEN TO BACKGROUND CHANGE ON SCROLL
  window.addEventListener("scroll", changeBg)

  // HEADER BACKGROUND STYLE
  const headerBgStyle = `headerContainer ${navBg && "active"}`

  // NAVLINK STYLE
  const navULStyle = ` navUL ${toggleMenu ? "show" : "hidden"}`


  // SET TOGGLE ICON FUNCTION
  const toggleIcon = (
    <i
      className="toggleIcon"
      onClick={() => settoggleMenu(!toggleMenu)}
    >
      {
        toggleMenu
          ? <AiOutlineClose />
          : <AiOutlineMenu />
      }
    </i>)

  return (
    <div className={headerBgStyle}>
      <div className="header">

        {/* COMPANY LOGO */}
        <a href="#">
          <img src={logo} className="logoImg" />
        </a>

        {/* DISPLAY TOGGLE MENU ICON */}
        {toggleIcon}


        {/* NAVLINK LIST */}
        <ul className={navULStyle}>
          {navList.map((item, index) => {
            return (
              <li key={index}>
                <a
                  href={`/${item.link}`}
                  className="navLink">
                  <p>{item.name}</p>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Header
