import bg1 from '../assets/bg1.jpg'
import bg2 from '../assets/bg2.jpg'
import bg3 from '../assets/home1.jpg'



export const slideList = [
  {
    img: bg1,
    title: "business strategy",
    text: "marketing and sales",
  },
  {
    img: bg3,
    title: "business strategy",
    title: "data management",
    // text: "Discover trends in datasets",
    text: "data processing and analysis",
  },
  {
    img: bg2,
    title: "product development",
    text: "Improve the quality of product",
  }

]