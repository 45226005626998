
import { AiOutlineMail, AiFillPhone} from 'react-icons/ai'
import { MdLocationOn } from 'react-icons/md'



export const contactList = [
  {
    id: 1,
    icon: <AiFillPhone />,
    title: "phone",
    desc: "+1 (905) 966-7187"

  },
  {
    id: 2,
    icon: <AiOutlineMail />,
    title: "email",
    desc: "contact@cloudwalkconsulting.com"
  },
  {
    id: 3,
    icon: <MdLocationOn />,
    title: "location",
    desc: "80 Bloor St W, Toronto ON, M5S 2V1"
  },

 
]