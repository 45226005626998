import React from 'react';
import { contactList } from '../../data/contactList';
import { serviceList } from '../../data/serviceList';
import { navList } from '../../data/navList';

import './index.css'

const Footer = () => {
  let date = new Date();
  //四位
  let yearFour = date.getFullYear()
  return (
    <div id="footer" >
      <div className=" footerContainer">
        <div className="col">
          <p className="footerTitle">Links</p>
          <ul className="footerUl">
            {navList.map((item, index) => {
              return (
                <a key={index} href={`/${item.link}`} >
                  <p className="footerP">{item.name}</p>
                </a>
              )
            })}


          </ul>
        </div>
        <div className="col">
          <p className="footerTitle">Services</p>
          <ul className="footerUl">
            {serviceList.map((item, index) => {
              return (
                <li key={index}>
                  <p className="footerP">{item.title}</p>
                </li>
              )
            })}


          </ul>
        </div>

        <div className="col">
          <p className="footerTitle">contact us</p>
          <ul className="footerUl">
            {contactList.map((item, index) => {
              return (
                <li
                  key={index}
                  className="contactLi"
                >
                  <i className="contactIcon">
                    {item.icon}
                  </i>
                  {/* <div className="iconRt"> */}
                    <p className="contactDesc">
                      {item.desc}
                    </p>
                  {/* </div> */}
                </li>
              )
            })}


          </ul>
        </div>

      </div>

      <p className="copyright"> Copyright &#169; CloudWalk {yearFour}</p>

    </div>
  )
}

export default Footer
