
import { SiMusicbrainz } from 'react-icons/si'
import { GiMaterialsScience } from 'react-icons/gi'
import { MdOutlineModelTraining } from 'react-icons/md'
import { GiReceiveMoney,GiProfit } from 'react-icons/gi'
import { FaMoneyCheckAlt,FaHandsHelping } from 'react-icons/fa'
import { BsShopWindow } from 'react-icons/bs'
 

// FaHandsHelping


export const serviceList = [
  {
    id: 1,
    icon: <BsShopWindow />,
    title: "Marketing",
    desc: "Are you a startup that needs help with a data-driven marketing strategy? CloudWalk can help you with the delivery of digital marketing solutions.",

    bgDesc: "CloudWalk delivery of digital marketing solutions, including marketing data collection, customer lifetime value analysis, marketing campaign design, analysis, and marketing strategy recommendation. CloudWalk successfully provides marketing consulting services in Canada, the USA, Thailand, and Singapore."
  },
  {
    id: 2,
    // icon: <GiMaterialsScience />,
    icon: <GiProfit />,

    title: "Sales",
    desc: "CloudWalk helps clients to know how well their brands are selling online compared to history and competitors. We provide real-time sales performance tracking reports.",

    bgDesc: "CloudWalk provide real-time sales performance tracking reports, such as GMV by brand and SKU based on required updating frequency, interactively market share and growth analysis, analysis of product portfolio to identify strengths and weaknesses, and sales and salespersons and channel analysis. We successfully increase sales and market share for our clients."
  },
  {
    id: 3,
    // icon: <MdOutlineModelTraining />,
    icon: <FaMoneyCheckAlt />,

    title: "Pricing",
    desc: "Do you have questions or problems with profit and sales in your real business? CloudWalk is able to identify the price for the products in order to maximize your profit.",

    bgDesc: "CloudWalk identify the price for the new products and the existing products in order to maximize your profit. We are proficient at the cost-benefit analysis, PED analysis, marketing-price analysis, and deployment of pricing strategies."
  },
  {
    id: 4,
    icon: <FaHandsHelping />,
    title: "Customer",
    desc: "CloudWalk is here to help you with the whole customer lifecycle, no matter whether you want to acquire a new customer or improve the customer retention rate.",

    bgDesc: "CloudWalk help you with the whole customer lifecycle. We use advanced data science skills to dig into customer behavior and help the client to extract impactful business insight and find opportunities. "
  },

]