import React from 'react'
import Title from '../Title/Title'
import about from '../../assets/about1.jpg'
import './index.css'

const About = () => {
  return (
    <div id="about" className="containerWidth">
      <div className="leftAbout">
        <Title title="about us" />
        <p className="aboutContent">CloudWalk is a leading provider of data intelligence solutions. Through top-notch AI technology and data processing and analysis capabilities, we successfully help clients with real-time and comprehensive data insight and implementation of data solutions and enable data-driven decisions leading to brand evolution.
        CloudWalk continues to serve leading customers in various fields such as e-Commerce, Telecom, personal care and beauty, food and beverage, daily household cleaners, pets, and automobiles and has been widely recognized by the industry.

          {/* CloudWalk continues to serve leading customers in various fields such as e-Commerce, Telecom, personal care and beauty, food and beverage, daily household cleaners, pets, and automobiles and has been widely recognized by the industry. */}
          {/* CloudWalk continues to serve leading customers in various fields such as e-Commerce, Telecom, personal care and beauty, food and beverage, daily household cleaners, pets, and automobiles and has been widely recognized by the industry. */}
        </p>
      </div>
      <img src={about} className="aboutImg" />
    </div>

  )
}

export default About

