import React from "react";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Slider from "./components/Slider/Slider";
import About from "./components/About/About";
import Service from "./components/Service/Service";
import Testimonials from "./components/Testimonials/Testimonials";
import Contact from "./components/Contact/Contact";
 

function App() {
  return (
    < >
      <Header />
      <Slider />
      <About />
      <Service />
      <Testimonials />
      <Contact />
      <Footer />
    </ >


  )
}

export default App;

