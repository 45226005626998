import React, { useEffect, useState } from 'react'
import './index.css'
import { slideList } from '../../data/slideList'
import { MdArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md'
import { BsDot } from 'react-icons/bs'
import { withRouter } from 'react-router'



const Slider = () => {

  const [activeIndex, setActiveIndex] = useState(0)

  // import backgroundImages
  const len = slideList.length

  // AUTO SLIDES
  useEffect(() => {
    let timer = setInterval(() => {
      setActiveIndex(activeIndex == len - 1 ? 0 : activeIndex + 1)
    }, 8000)
    return () => {
      clearInterval(timer)
    }
  }, [activeIndex])


  const handleClick = (dir) => {
    if (dir === "left") {
      setActiveIndex(activeIndex === 0 ? len - 1 : activeIndex - 1)
    } else {
      setActiveIndex(activeIndex === len - 1 ? 0 : activeIndex + 1)
    }
  }
  const setDotColor = (index) => {
    return index === activeIndex ? `var(--first-color)` : "white"
  }

  {/* BOTTOM DOTS */ }
  const dots = (<div className="dotContainer">
    {slideList.map((item, index) => {
      return (
        <i
          onClick={() => setActiveIndex(index)}
          className="dotIcon"
          style={{ color: setDotColor(index) }}
          key={index}
        >
          <BsDot />
        </i>
      )
    })}
  </div>)


  {/* LEFT ARROW */ }
  const leftArrow = (
    <i
      onClick={() => handleClick("left")}
      className="arrowIcon"
      style={{ left: "var(--mg-5)" }}
    >
      <MdArrowBackIos />
    </i>
  )

  {/* RIGHT ARROW */ }
  const rightArrow = (
    <i
      onClick={() => handleClick("right")}
      className="arrowIcon"
      style={{ right: "var(--mg-5)" }}
    >
      <MdOutlineArrowForwardIos />
    </i>
  )


  const sliderDesc = (<div className="sliderDesc">
    <p className="sliderTitle">{slideList[activeIndex].title}</p>
    <p className="sliderTxt">{slideList[activeIndex].text}</p>
      <a
        className="sliderBtn"
        href="/#contact"
        style={{ marginRight: "var(--mg-3" }}
      >
        contact us
      </a>
      <a
        style={{ background: "transparent", border: "white 2px solid" }}
        className="sliderBtn"
        href="/#services"

      >
        View more
      </a>
 
  </div>
  )

  return (
    <div id="Slider"  >
      <div className="containerWidth" >

        {/* left arrow */}
        {leftArrow}


        <img
          src={`${slideList[activeIndex].img}`}
          className="bgImg"
        />

        {sliderDesc}

        {/* RIGHT ARROW */}
        {rightArrow}

        {/* BOTTOM DOTS */}
        {dots}
      </div>
    </div>
  )
}

export default Slider

// color: index === activeIndex ? `var(--first-color)` : "white"
