import React from 'react'
import Title from '../Title/Title'
import './index.css'
import { serviceList } from '../../data/serviceList'


const Service = () => {
  return (
    <div id="services" >
      <div className="serviceContainer containerWidth">
        <Title
          title="Services"
          textalign="center"
        />

        <p className="serviceSubtitle">
          By interpreting massive data from e-commerce platforms and social media channels, CloudWalk provide clients with real-time insights and recommendations on business strategy, product development, marketing and sales, and e-commerce operations.
          </p>

        <ul className="serviceUL" >
          {serviceList.map((item, index) => {
            return (
              <li key={index} className="serviceLi">
                <i className="serviceIcon">{item.icon}</i>
                <h4 className="serviceTitle">{item.title}</h4>
                <p className="serviceTxt">{item.desc}</p>
                <div className="bgTxt">
                  {item.bgDesc}
                </div>
              </li>
            )
          })

          }
        </ul>
      </div>
    </div>
  )
}

export default Service
