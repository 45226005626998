export const navList = [
{
  id:1,
  link:"#",
  name:"Home"
},
{
  id:2,
  link:"#about",
  name:"About"
},
{
  id:3,
  link:"#services",
  name:"services"
},
{
  id:4,
  link:"#testimonials",
  name:"testimonials"
},
{
  id:5,
  link:"#contact",
  name:"contact"
},

]