import React, { useState, useRef } from 'react'
import './index.css'
import Title from '../Title/Title'
import emailjs from '@emailjs/browser';
import { SiMinutemailer } from 'react-icons/si'


const Contact = () => {
  const form = useRef();

  // sendStatus: 1:email wasn't sent; 2: eamil is sending; 3:email sent successuflly
  const [sendStatus, setSendStatus] = useState("start")

  const sendEmail = (e) => {
    e.preventDefault();
    setSendStatus("sending")
    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    )
      .then(res => {
        setSendStatus("succesfull")
        setTimeout(() => {
          setSendStatus("start")
        }, 10000)
      }, err => {
        alert(err)
        console.log(err)
        console.log(err.message)
        setSendStatus("failed")
      });
  };

  return (
    <div id="contact" >
      <div className="containerWidth contactContainer" >
        <Title
          title="contact us"
        />

        <div className="contactContent">
          <form
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="userInfo">
              <div className="userInfoRow">
                <span className="contactTitle">
                  name :
                </span >
                <input
                  required
                  name="user_name"
                />
              </div>
              <div className="userInfoRow">
                <span className="contactTitle">
                  subject :
                </span >
                <input
                  required
                  name="user_subject"
                />
              </div>
              <div className="userInfoRow">
                <span className="contactTitle">
                  email :
                </span >
                <input
                  type="email"
                  required
                  name="user_email"
                />
              </div>
            </div>

            <div className="msg">
              <span
                className="contactTitle"
              >
                message :
              </span >
              <textarea
                style={{
                  // resize: "none",
                  height: "150px"
                }}
                rows="8"

                required
                name="message"
              />
            </div>
            <button
              disabled={sendStatus === "sending"}
            >
              <i className="sendIcon"><SiMinutemailer /></i>
              {sendStatus === "sending" ? "sending" : "submit"}
            </button>

            {/* confirm with clients that email was sent succesfully  */}
            {sendStatus === "succesfull" &&
              <p className="sent" >
                The email has been sent succesfully. Thanks!
              </p>}
            {/* confirm with clients that email was sent failed  */}
            {sendStatus === "failed" &&
              <p className="fail" >
                Email sending failed, please resend!
              </p>}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact
